import React from "react";
import { Layout } from "../components/layout";
import { GridOneText } from "../components/grids";
import { H1UppercaseGrey } from "../components/headings";
import styled from "styled-components";
import { Link } from "gatsby";

export default function Impressum() {
    const TextDiv = styled.div`
        font-size: 1rem;
        text-align: left;
        @media screen and (max-width: 813px) {
            font-size: 0.9rem;
            h1 {
                font-size: 1.5rem;
                text-align: center;
            }
        }
    `;
    return (
        <Layout title="Impressum" lang="de" actSite="impressum">
            <GridOneText>
                <TextDiv>
                    <H1UppercaseGrey>Impressum</H1UppercaseGrey>
                    Metallbau Kessler GmbH{" & "}Co. KG
                    <br />
                    Sautierstraße 23
                    <br />
                    78187 Geisingen
                    <br />
                    Tel. 0 77 04 92799 0<br />
                    Fax: 0 77 04 92799-10 (Verwaltung)
                    <br />
                    E-Mail:{" "}
                    <Link to="mailto:info@kessler-metall.de">
                        info@kessler-metall.de
                    </Link>
                    <br />
                    <br />
                    Amtsgericht Stuttgart, HRA 728084
                    <br />
                    <br />
                    Geschäftsführer: Waldemar Maier
                    <br />
                    Datenschutzbeauftragte: Irina Maier
                    <br />
                    <br />
                    <strong>
                        Persönlich haftende Gesellschafterin der GmbH{" & "}
                        Co.KG:
                    </strong>
                    <br />
                    W. Maier Verwaltungs-GmbH
                    <br />
                    Sitz: Geisingen
                    <br />
                    Amtsgericht Stuttgart HRB 743864
                    <br />
                    Geschäftsführer: Waldemar Maier
                    <br />
                    <br />
                    Zusätzliche Informationen gem. Gesetz über rechtliche
                    Rahmenbedingungen für den elektronischen Geschäftsverkehr
                    (EGG) in Verbindung mit § 6 Teledienstgesetz (TDG)
                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a
                    Umsatzsteuergesetz: DE814846718
                </TextDiv>
            </GridOneText>
        </Layout>
    );
}
